import React from "react"
import { Link } from 'gatsby';

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ pageContext }) => (
  <Layout>
    <SEO 
      lang="en"
      path={pageContext.pageUrl}
      title="404: Not found" 
      noindex={true}
    />
    <div id="header">
        <div className="section-content">
            <div className="big-text">
                <h1 className="t-left f-large normal">Opst!</h1>
                <div className="separator-small"></div>
                <h2 className="t-left f-medium normal">We couldn't find what you are looking for.</h2>
                <br />
                <p className="t-left f-normal normal">If was a property, maybe is already sold. No problem!</p>
                <p className="t-left f-normal normal">
                    <Link to="/contact/" className="underline">Contact me</Link> or just <Link to="/properties/" className="underline">check the properties</Link>
                </p>
            </div>
        </div>
    </div>
  </Layout>
)

export default NotFoundPage
